import React, { useState, useEffect } from 'react';
import DropDown from '../FormInput/DropDown';
import reasonsOptions from './reasons.json';

import {
  InnerSectionStyled as InnerSection,
  OutlineBoxStyled as OutlineBox,
  OutlineListStyled as OutlineList,
  OlItemStyled as OlItem,
  Title1Styled as Title1,
  Title2Styled as Title2,
  ItemBoxStyled as ItemBox,
} from '../styles/UnsubscribeStyled';

const Reason = () => {
  const [reasons, setReasons] = useState([]);

  useEffect(() => {
    setReasons(reasonsOptions.map((v) => ({ label: v.name, value: v.code })));
  }, []);

  return (
    <OutlineBox>
      <Title1>¿Por qué quieres dar de baja?</Title1>
      <InnerSection>
        <OutlineList>
          <OlItem>
            <Title2>Motivo</Title2>
            <ItemBox>
              <DropDown
                name="reason"
                placeholder="Seleccionar motivo"
                options={reasons}
              />
            </ItemBox>
          </OlItem>
        </OutlineList>
      </InnerSection>
    </OutlineBox>
  );
};

export default Reason;
