import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import Button from '../FormButton/Button';

import {
  SentSvgStyled as SentSvg,
  ContentBoxStyled as ContentBox,
  ContentHeaderStyled as ContentHeader,
  Title2Styled as Title2,
  ConfirmationMessageStyled as ConfirmationMessage,
} from './styles/RequestSentStyled';

const RequestSent = ({ email }) => {
  return (
    <ContentBox>
      <ContentHeader>
        <SentSvg />
        <Title2>¡Listo!</Title2>
        <ConfirmationMessage>
          Tu solicitud de dar te baja fue enviada. En un plazo de 24 horas te
          enviamos la confirmación a <strong>{email}</strong>
        </ConfirmationMessage>
      </ContentHeader>
      <Button
        color="bubbleGum"
        maxWidth="100%"
        margin="8px auto 0"
        onClick={() => navigate('/home')}
      >
        Volver al inicio
      </Button>
    </ContentBox>
  );
};

RequestSent.propTypes = {
  email: PropTypes.string.isRequired,
};

export default RequestSent;
