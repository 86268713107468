import React from 'react';
import PropTypes from 'prop-types';
import {
  DefaultButton,
  DisabledButton,
  OutlineButton,
  TransparentButton,
} from './styles/ButtonStyled';

const Button = ({
  className,
  children,
  color,
  margin,
  maxWidth,
  outline,
  transparent,
  disabled,
  onClick,
  iconOnly,
  height,
  width,
  dataButtonId,
  dataTestId,
  unhover,
  textTransform,
  fontWeight,
  padding,
}) => {
  let CompButton = null;
  if (disabled) CompButton = DisabledButton;
  else if (outline) CompButton = OutlineButton;
  else if (transparent) CompButton = TransparentButton;
  else CompButton = DefaultButton;

  return (
    <CompButton
      className={className}
      color={color}
      margin={margin}
      maxWidth={maxWidth}
      transparent={transparent}
      onClick={onClick}
      iconOnly={iconOnly}
      height={height}
      width={width}
      data-button-id={dataButtonId}
      data-testid={dataTestId}
      unhover={unhover}
      textTransform={textTransform}
      fontWeight={fontWeight}
      padding={padding}
    >
      {children}
    </CompButton>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  color: PropTypes.oneOf(['purple', 'bubbleGum', 'white']),
  margin: PropTypes.string,
  maxWidth: PropTypes.string,
  outline: PropTypes.bool,
  transparent: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  iconOnly: PropTypes.bool,
  height: PropTypes.string,
  width: PropTypes.string,
  dataButtonId: PropTypes.string,
  dataTestId: PropTypes.string,
  unhover: PropTypes.bool,
  textTransform: PropTypes.string || PropTypes.bool,
  fontWeight: PropTypes.number,
  padding: PropTypes.string,
};

Button.defaultProps = {
  className: '',
  color: 'purple',
  margin: '0 auto 0 auto',
  maxWidth: 'fit-content',
  outline: false,
  transparent: false,
  disabled: false,
  onClick: null,
  children: null,
  iconOnly: false,
  height: 'auto',
  width: '100%',
  dataButtonId: null,
  dataTestId: null,
  unhover: false,
  textTransform: 'uppercase',
  fontWeight: 600,
  padding: '12px 20px',
};

export default Button;
