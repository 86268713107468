import styled from 'styled-components';
import { ReactComponent as SentSvg } from '../../UnsubscribeIcons/sent.svg';

const SentSvgStyled = styled(SentSvg)`
  margin: 4px 0 20px;
`;

const ContentBoxStyled = styled.section`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  margin: 0 auto 16px;
  box-shadow: 0px 2px 4px rgba(45, 20, 65, 0.15);

  @media (min-width: 769px) {
    max-width: 312px;
    box-shadow: none;
    padding: 0 0 16px;
  }
`;

const ContentHeaderStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SummaryBoxStyled = styled.div`
  background-color: #f4f4f7;
  border-radius: 8px;
  padding: 16px;

  @media (min-width: 769px) {
    padding: 24px;
  }
`;

const SummaryListStyled = styled.ul`
  display: flex;
  flex-direction: column;
`;

const SummaryItemStyled = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  border-top: 1px solid #e6e2e8;
  padding: 16px 0 16px;

  &:first-child {
    border-top: none;
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
`;

const Title2Styled = styled.h2`
  font-family: cerapro;
  font-size: 18px;
  font-weight: 600;
  color: #381451;

  svg {
    margin-right: 8px;
    vertical-align: bottom;
    flex-shrink: 0;
  }
`;

const ConfirmationMessageStyled = styled.p`
  font-family: cerapro;
  font-size: 16px;
  line-height: 19px;
  color: #381451;
  text-align: center;
  padding: 16px 4px;

  strong {
    font-weight: 600;
  }
`;

const ServiceListStyled = styled.ul`
  display: flex;
  flex-direction: column;
  padding-top: 4px;
`;

const ServiceItemStyled = styled.li`
  display: flex;
  padding-top: 8px;

  svg {
    flex-shrink: 0;
  }
`;

const LineDescriptionStyled = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  line-height: 20px;
  color: #381451;

  small {
    font-size: 14px;
    line-height: 20px;
  }
`;

export {
  SentSvgStyled,
  ContentBoxStyled,
  ContentHeaderStyled,
  SummaryBoxStyled,
  SummaryListStyled,
  SummaryItemStyled,
  Title2Styled,
  ConfirmationMessageStyled,
  ServiceListStyled,
  ServiceItemStyled,
  LineDescriptionStyled,
};
