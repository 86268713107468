import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import Loading from '../../../../Loading/Loading';
import UnsubscribeForm from './UnsubscribeForm';
import AccountService from '../../../../../services/AccountService';
import {
  HeaderNavStyled as HeaderNav,
  ContainerStyled as Container,
  Title1Styled as Title,
  HeaderStyled as Header,
  HeaderLeyendStyled as HeaderLeyend,
} from './styles/UnsubscribeStyled';
import DownService from '../../../../../services/DownService';
import RequestSent from './RequestSent/RequestSent';
import ServiceError from './ServiceError/ServiceError';
import { ReactComponent as ArrowLeftSvg } from './UnsubscribeIcons/arrow-left.svg';
import GoogleTagManager from '../../../../../helpers/GoogleTagManager';

const officesUrl =
  'https://www.wom.cl/estamos-contigo/?utm_source=Miwom_fibra&utm_medium=Baja_voluntaria&utm_id=contactanos';

const Unsubscribe = () => {
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState([]);
  const [error, setError] = useState(false);
  const [sended, setSended] = useState(false);
  const [email, setEmail] = useState('');
  const [retry, setRetry] = useState(() => null);

  const fetchUser = async () => {
    setLoading(true);
    try {
      const [{ user }] = await AccountService.getPlanData();
      setUserData(user);
      setLoading(false);
    } catch ({ status, data }) {
      setError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUser();
    GoogleTagManager.pushToDataLayer({
      event: 'pagina-virtual',
      page: '/ayuda/bajas',
      pagename: 'Ayuda - Dar de Baja',
    });
  }, []);

  const onSubmit = (formValues) => {
    if (typeof window !== 'undefined') {
      window.scrollTo({ behavior: 'smooth', top: 0 });
    }

    const payload = {
      reason: formValues.reason,
      firstname: userData.name,
      lastname: userData.lastName,
      email: formValues.email,
      contact: `56${formValues.contact}`,
    };

    setEmail(formValues.email);
    setError(false);
    setLoading(true);
    setSended(false);
    setRetry(() => () => onSubmit({ ...formValues }));

    DownService.terminateOrder(payload)
      .then(([response]) => {
        const { retentionRegister } = response;
        setError(!retentionRegister);
        setSended(true);
        if (!retentionRegister) {
          GoogleTagManager.pushToDataLayer({
            event: 'pagina-virtual',
            page: '/ayuda/bajas/baja-error',
            pagename: 'Ayuda - Dar de Baja - Baja Error',
          });
        } else {
          GoogleTagManager.pushToDataLayer({
            event: 'pagina-virtual',
            page: '/ayuda/bajas/baja-exito',
            pagename: 'Ayuda - Dar de Baja - Baja exito',
          });
        }
      })
      .catch(() => {
        setError(true);
        GoogleTagManager.pushToDataLayer({
          event: 'pagina-virtual',
          page: '/ayuda/bajas/baja-error',
          pagename: 'Ayuda - Dar de Baja - Baja Error',
        });
      })
      .finally(() => setLoading(false));
  };

  const Content = () => {
    if (loading) {
      return (
        <>
          <Header>
            <Title>Dar de baja servicios</Title>
          </Header>
          <Loading />
        </>
      );
    }
    if (error) {
      return (
        <>
          <Header>
            <Title>Dar de baja servicios</Title>
          </Header>
          <ServiceError retry={retry} />
        </>
      );
    }

    if (!error && !loading && sended) {
      return (
        <>
          <Header>
            <Title>Comprobante de solicitud</Title>
          </Header>
          <RequestSent email={email} />
        </>
      );
    }
    return (
      <>
        <Header>
          <Title>Dar de baja servicios</Title>
          <HeaderLeyend>
            También puedes dar de baja llamando al{' '}
            <a href="tel:+6006001106">600 600 1106</a> (celulares), al{' '}
            <a href="tel:+56937400691">+56937400691</a> (red fija) o en
            cualquiera de <a href={officesUrl}>nuestras sucursales</a>
          </HeaderLeyend>
        </Header>
        <UnsubscribeForm user={userData} onSubmit={onSubmit} />
      </>
    );
  };

  return (
    <>
      <HeaderNav>
        <ArrowLeftSvg onClick={() => navigate('/home/contactanos')} />
        <span>Volver</span>
      </HeaderNav>
      <Container>
        <Content />
      </Container>
    </>
  );
};

export default Unsubscribe;
