import React from 'react';

import PropTypes from 'prop-types';
import {
  InnerSectionStyled as InnerSection,
  OutlineBoxStyled as OutlineBox,
  OutlineListStyled as OutlineList,
  OlItemStyled as OlItem,
  Title1Styled as Title1,
  Title2Styled as Title2,
} from '../styles/UnsubscribeStyled';
import {
  PersonalDataGridStyled as PersonalDataGrid,
  GridItemStyled as GridItem,
  PersonalDataFormStyled as PersonalDataForm,
} from './styles/PersonalDataStyled';
import {
  UserIconSvg,
  ContactIconSvg,
  EditIconSvg,
} from '../UnsubscribeIcons/index';

import TextInput from '../FormInput/TextInput';

const PersonalData = ({ user }) => {
  let [firstname, lastName, documentNumber] = ['', '', ''];
  if (user) {
    firstname = user.name;
    lastName = user.lastName;
    documentNumber = user.rut.replace(
      /(\d{1,2})(\d{3})(\d{3})([0-9kK])/,
      '$1.$2.$3-$4'
    );
  }

  return (
    <OutlineBox>
      <Title1>Datos personales</Title1>
      <InnerSection>
        <OutlineList>
          <OlItem>
            <Title2>
              <UserIconSvg />
              Datos del titular
            </Title2>
          </OlItem>
          <OlItem>
            <PersonalDataGrid>
              <GridItem>
                <strong>Nombres:</strong>
              </GridItem>
              <GridItem>{firstname}</GridItem>
              <GridItem>
                <strong>Apellidos:</strong>
              </GridItem>
              <GridItem>{lastName}</GridItem>
              <GridItem>
                <strong>RUT:</strong>
              </GridItem>
              <GridItem>{documentNumber}</GridItem>
            </PersonalDataGrid>
          </OlItem>
        </OutlineList>
        <OutlineList>
          <OlItem>
            <Title2>
              <ContactIconSvg />
              Datos de Contacto
            </Title2>
          </OlItem>
          <OlItem>
            <PersonalDataForm>
              <TextInput
                label="Email"
                name="email"
                type="email"
                icon={<EditIconSvg />}
              />
              <TextInput
                label="Número de contacto"
                name="contact"
                type="text"
                prefix="+56"
                icon={<EditIconSvg />}
              />
            </PersonalDataForm>
          </OlItem>
        </OutlineList>
      </InnerSection>
    </OutlineBox>
  );
};

PersonalData.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    lastName: PropTypes.string,
    rut: PropTypes.string,
  }).isRequired,
};

export default PersonalData;
