import React from 'react';
import SEO from '../../../components/seo';
import Unsubscribe from '../../../components/pages/home/Contactanos/Unsubscribe';
import { AccountService } from '../../../services';
import { SiteLayout } from '../../../components/Layouts';

const UnsubscribePage = () => {
  const userName = AccountService.getUserName();

  return (
    <>
      <SEO title="Dar de baja" />
      <SiteLayout user={userName} mobileWithoutName>
        <Unsubscribe />
      </SiteLayout>
    </>
  );
};

export default UnsubscribePage;
