import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import Select from 'react-select';
import {
  DropDownBoxStyled as DropDownBox,
  DropDownErrorStyled as DropDownError,
} from './styles/FormInputStyled';

const theme = (t) => ({
  ...t,
  colors: {
    ...t.colors,
    primary: '#e6e6e6',
    primary50: 'f4f4f7',
  },
});

const DropDown = ({
  className,
  name,
  options,
  disabled,
  loading,
  placeholder,
}) => {
  const [field, meta, helpers] = useField(name);
  const invalid = meta.error && meta.touched;

  const styles = {
    control: (provided) => ({
      ...provided,
      borderColor: invalid ? '#fd3a4a' : '#b3a6ba',
      boxShadow: 'none',
      borderRadius: '5px',
      padding: '3px 6px',
      '&:hover': {
        borderColor: '#b3a6ba',
      },
    }),
    singleValue: (provided, state) => ({
      ...provided,
      opacity: state.isDisabled ? 0.5 : 1,
      transition: 'opacity 300ms',
      color: '#2d1441',
      fontSize: '16px',
    }),
    option: (provided, state) => ({
      ...provided,
      color: '#2d1441',
      backgroundColor: (() => {
        if (state.isSelected) return '#e6e6e6';
        if (state.isFocused) return '#f4f4f7';
        return '#ffffff';
      })(),
    }),
    indicatorSeparator: () => ({ display: 'none' }),
  };

  return (
    <DropDownBox invalid={invalid}>
      <Select
        className={className}
        name={name}
        aria-label={name}
        aria-invalid={invalid}
        options={options}
        disabled={disabled}
        isLoading={loading}
        placeholder={placeholder}
        onChange={(option) => helpers.setValue(option.value)}
        onBlur={field.onBlur}
        value={options.find(
          ({ value }) => JSON.stringify(value) === JSON.stringify(field.value)
        )}
        theme={theme}
        styles={styles}
      />
      {invalid && <DropDownError>{meta.error}</DropDownError>}
    </DropDownBox>
  );
};

DropDown.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  placeholder: PropTypes.string,
};

DropDown.defaultProps = {
  className: '',
  disabled: false,
  loading: false,
  placeholder: '',
};

export default DropDown;
