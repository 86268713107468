import styled from 'styled-components';

const PersonalDataGridStyled = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  row-gap: 14px;
  font-size: 16px;
`;

const GridItemStyled = styled.div`
  &:nth-child(n + 3) {
    border-top: 1px solid #e6e2e8;
    padding-top: 14px;
  }

  strong {
    font-weight: 600;
    padding-right: 8px;
  }
`;

const PersonalDataFormStyled = styled.div`
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 8px;
  }
`;

export { PersonalDataGridStyled, GridItemStyled, PersonalDataFormStyled };
