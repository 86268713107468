import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import {
  TextInputStyled,
  FormInputBoxStyled as FormInputBox,
  InputLabelStyled as InputLabel,
  InputPrefixStyled as InputPrefix,
  InputErrorStyled as InputError,
} from './styles/FormInputStyled';
import { ReactComponent as WarningIconSvg } from '../UnsubscribeIcons/warning-icon.svg';

const TextInput = ({
  className,
  label,
  name,
  type,
  disabled,
  prefix,
  placeholder,
  icon,
  renderValue,
}) => {
  const [field, meta] = useField(name);
  const invalid = meta.error && meta.touched;

  const InputIcon = () => {
    if (invalid) return <WarningIconSvg />;
    return icon || null;
  };

  return (
    <FormInputBox className={className} disabled={disabled} invalid={invalid}>
      <InputIcon />
      <InputLabel htmlFor={name} disabled={disabled}>
        {label}
      </InputLabel>
      {prefix && <InputPrefix>{prefix}</InputPrefix>}
      <TextInputStyled
        name={name}
        aria-label={name}
        aria-invalid={invalid}
        type={type}
        disabled={disabled}
        placeholder={placeholder}
        onChange={field.onChange}
        onBlur={field.onBlur}
        value={renderValue ? renderValue(field.value) : field.value}
      />
      {invalid && <InputError>{meta.error}</InputError>}
    </FormInputBox>
  );
};

TextInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  prefix: PropTypes.string,
  placeholder: PropTypes.string,
  icon: PropTypes.node,
  renderValue: PropTypes.func,
};

TextInput.defaultProps = {
  className: '',
  disabled: false,
  prefix: '',
  placeholder: '',
  icon: null,
  renderValue: null,
};

export default TextInput;
