import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import Button from '../FormButton/Button';

import {
  ErrorSvgStyled as ErrorSvg,
  ContentBoxStyled as ContentBox,
  ErrorTitleStyled as ErrorTitle,
  ErrorMessageStyled as ErrorMessage,
} from './styles/ServiceErrorStyled';

const ServiceError = ({ retry }) => {
  return (
    <ContentBox>
      <ErrorSvg />
      <ErrorTitle>No hemos podido enviar tu solicitud</ErrorTitle>
      <ErrorMessage>
        Algo falló en nuestro sistema, vuelve a intentarlo más tarde.
      </ErrorMessage>
      <Button
        color="bubbleGum"
        maxWidth="100%"
        margin="12px auto 0"
        onClick={retry}
      >
        Reintentar
      </Button>
      <Button
        outline
        maxWidth="100%"
        margin="12px auto 0"
        onClick={() => navigate('/home')}
      >
        Volver al inicio
      </Button>
    </ContentBox>
  );
};

ServiceError.propTypes = {
  retry: PropTypes.func.isRequired,
};

export default ServiceError;
