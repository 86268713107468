import styled from 'styled-components';
import { ReactComponent as ErrorSvg } from '../../UnsubscribeIcons/error.svg';

const ErrorSvgStyled = styled(ErrorSvg)`
  margin: 4px auto 20px;
`;

const ContentBoxStyled = styled.section`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  margin: 0 auto 16px;
  box-shadow: 0px 2px 4px rgba(45, 20, 65, 0.15);

  @media (min-width: 769px) {
    max-width: 312px;
    box-shadow: none;
    padding: 0 0 16px;
  }
`;

const ErrorTitleStyled = styled.h2`
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #381451;
  text-align: center;
`;

const ErrorMessageStyled = styled.h2`
  font-size: 16px;
  line-height: 19px;
  color: #381451;
  text-align: center;
  padding: 12px 4px 16px;
  font-weight: 400;
`;

export {
  ErrorSvgStyled,
  ContentBoxStyled,
  ErrorTitleStyled,
  ErrorMessageStyled,
};
