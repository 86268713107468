import styled from 'styled-components';

const backgroundColor = {
  purple: '#4D008C',
  bubbleGum: '#E62771',
  white: '#FFF',
  disabled: '#DDD4D8',
};

const textColor = {
  purple: '#FFF',
  bubbleGum: '#FFF',
  white: '#2D1441',
  disabled: '#DDD4D8',
  outline: '#FFF',
};

const DefaultButton = styled.div`
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ padding }) => padding};
  border-radius: 6px;
  font-family: 'cerapro';
  font-size: 14px;
  font-weight: ${({ fontWeight }) => fontWeight} !important;
  cursor: pointer;
  transition: background-color 0.5s ease, color 0.5s ease;
  width: ${(props) => props.width};
  text-decoration: none;
  text-align: center;
  outline: none;
  white-space: nowrap;
  border: 1px solid;
  height: ${(props) => props.height};
  margin: ${(props) => props.margin};
  max-width: ${(props) => props.maxWidth};
  background-color: ${(props) =>
    props.transparent ? 'transparent' : backgroundColor[props.color]};
  color: ${(props) => textColor[props.color]};
  border-color: ${(props) => backgroundColor[props.color]};

  svg {
    margin-right: ${(props) => (props.iconOnly ? '0px' : '10px')};

    path {
      fill: ${(props) => textColor[props.color]};
      transition: fill 0.3s;
    }
  }
}

${({ textTransform }) =>
  typeof textTransform !== 'boolean'
    ? textTransform !== 'none' && `text-transform: ${textTransform};`
    : textTransform && `text-transform: uppercase;`}

@media screen and (min-width: 768px) {
  text-transform: uppercase;
}

${(props) =>
  props.unhover
    ? ''
    : `
        @media (hover: hover) {
        :hover {
          background-color: ${
            props.transparent ? 'transparent' : textColor[props.color]
          };
            color: ${backgroundColor[props.color]};
            svg {
              path {
                fill: ${backgroundColor[props.color]};
              }
              circle {
                fill: ${backgroundColor[props.color]};
              }
           }
        }
      }
  `}
`;

const OutlineButton = styled(DefaultButton)`
  background-color: ${(props) => (props.transparent ? 'transparent' : '#FFF')};
  color: ${(props) => backgroundColor[props.color]};
  border-color: ${(props) => backgroundColor[props.color]};

  svg {
    path {
      fill: ${(props) => backgroundColor[props.color]};
    }
  }

  @media (hover: hover) {
    :hover {
      background-color: ${(props) => backgroundColor[props.color]};
      color: ${textColor.outline};

      svg {
        path {
          fill: ${(props) => textColor[props.color]};
        }
      }
    }
  }
`;

const DisabledButton = styled(DefaultButton)`
  background-color: ${backgroundColor.disabled};
  color: ${textColor.outline};
  border-color: ${backgroundColor.disabled};
  pointer-events: none;

  svg {
    path {
      fill: ${textColor.outline};
    }
  }
`;

const TransparentButton = styled(DefaultButton)`
  background-color: transparent;
  font-size: 16px;
  font-weight: 500;
  color: #e92070;
  border-color: transparent;
  border-bottom: #e92070 1px solid;
  border-radius: 0;
  text-transform: capitalize;
  width: 90px;

  &:hover {
    color: #4d008c;
    border-bottom: #4d008c 2px solid;
  }
`;

export { DefaultButton, OutlineButton, DisabledButton, TransparentButton };
