import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { MainSectionStyled as MainSection } from './styles/UnsubscribeStyled';
import PersonalData from './PersonalData/PersonalData';
import Reason from './Reason/Reason';
import Button from './FormButton/Button';

const initialValues = {
  reason: '',
  email: '',
  contact: '',
};

yup.setLocale({
  mixed: {
    notType: 'Campo inválido',
    required: 'Campo obligatorio',
  },
  string: {
    email: 'Debes ingresar un email válido',
  },
});

const validationSchema = yup.object({
  reason: yup.string().required(),
  email: yup
    .string()
    .email('Debes ingresar un email válido')
    .required('Campo obligatorio'),
  contact: yup
    .string()
    .matches(/^[0-9]{9}$/, { message: 'Debes ingresar un número válido' })
    .required('Campo obligatorio')
    .length(9),
});

const UnsubscribeForm = ({ user, onSubmit }) => {
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit }) => {
          return (
            <Form>
              <MainSection>
                <PersonalData user={user} />
                <Reason />
              </MainSection>
              <Button
                color="bubbleGum"
                maxWidth="300px"
                margin="10px auto 0"
                onClick={handleSubmit}
              >
                Continuar
              </Button>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

UnsubscribeForm.propTypes = {
  user: PropTypes.shape({
    lastName: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    rut: PropTypes.string.isRequired,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default UnsubscribeForm;
