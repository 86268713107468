import { AccountService } from '../services';

const mapping = {
  EVENT: 'event',
  PAGE: 'page',
  PAGENAME: 'pagename',
  USERID: 'userID',
  EVENTCATEGORY: 'evento-interactivo-categoria',
  EVENTACTION: 'evento-interactivo-accion',
  EVENTTAG: 'evento-interactivo-etiqueta',
  NUMERODOCUMENTO: 'numeroDocumento',
};

const mapParams = (params) =>
  Object.fromEntries(
    Object.entries(params).map(([key, val]) => [
      mapping[key.toUpperCase()],
      val,
    ])
  );

const pushToDataLayer = (params) => {
  if (
    typeof window !== 'undefined' &&
    typeof window.dataLayer !== 'undefined'
  ) {
    const rutNumber = AccountService.getRut();
    const paramsCloned = { ...params };
    if (params.page !== '/' && rutNumber) {
      paramsCloned.userId = rutNumber;
    }
    const paramsMapped = mapParams(paramsCloned);
    window.dataLayer.push(paramsMapped);
  }
  return null;
};

const tagInteractiveEvent = ({ eventCategory, eventAction, eventTag }) => {
  if (
    typeof window !== 'undefined' &&
    typeof window.dataLayer !== 'undefined'
  ) {
    window.dataLayer.push({
      event: 'evento-interactivo',
      'evento-interactivo-categoria': eventCategory,
      'evento-interactivo-accion': eventAction,
      'evento-interactivo-etiqueta': eventTag,
    });
  }
  return null;
};

const GoogleTagManager = {
  pushToDataLayer,
  tagInteractiveEvent,
};

export default GoogleTagManager;
