import styled, { css } from 'styled-components';

const FormInputBoxStyled = styled.div`
  position: relative;
  display: flex;
  background-color: #ffffff;
  border: 1px solid #b3a6ba;
  border-radius: 5px;
  padding: 20px 38px 0 14px;

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: #f4f4f7;
      border: 1px solid #b3a6ba;
    `}

  ${({ invalid }) =>
    invalid &&
    css`
      margin-bottom: 20px;
      border: 1px solid #fd3a4a;
    `}

  svg {
    position: absolute;
    top: 28px;
    right: 12px;
  }
`;

const InputLabelStyled = styled.label`
  position: absolute;
  top: 11px;
  left: 14px;
  font-family: cerapro;
  font-size: 14px;
  color: #7c6c8a;
  color: ${({ disabled }) => (disabled ? '#b3a6ba' : '#7c6c8a')};
`;

const TextInputStyled = styled.input`
  display: block;
  width: 100%;
  height: 38px;
  margin: 0;
  padding: 0;
  background-color: inherit;
  border: none;
  font-family: cerapro;
  font-size: 16px;
  color: #000;

  &:disabled {
    color: #7c6c8a;
  }

  &:focus {
    outline: none;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
  }
  &:-webkit-autofill:disabled {
    -webkit-box-shadow: 0 0 0 30px #f4f4f7 inset;
  }
`;

const InputPrefixStyled = styled.span`
  display: block;
  padding: 11px 4px 0 0;
  font-family: cerapro;
  font-size: 16px;
  color: #000;
`;

const InputErrorStyled = styled.span`
  display: block;
  height: 20px;
  position: absolute;
  right: 0px;
  top: 59px;
  border-radius: 10px;
  background: #fd3a4a;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  padding: 0 10px;

  &::before {
    position: absolute;
    top: -3px;
    right: 24px;
    width: 0;
    height: 0;
    border: 3px solid #fd3a4a;
    border-right-color: transparent;
    border-bottom-color: transparent;
    transform: rotate(45deg);
    content: '';
  }
`;

const TextAreaStyled = styled.textarea`
  resize: none;
  background-color: #ffffff;
  border: 1px solid #b3a6ba;
  border-radius: 5px;
  font-size: 16px;
  line-height: 19px;
  color: #381451;
  padding: 14px 16px;
  width: 100%;
  height: ${({ height }) => height};

  &:focus {
    outline: none;
  }
`;

const DropDownBoxStyled = styled.div`
  position: relative;
  margin-bottom: ${({ invalid }) => (invalid ? '20px' : 0)};
`;

const DropDownErrorStyled = styled(InputErrorStyled)`
  top: auto;
`;

export {
  FormInputBoxStyled,
  InputLabelStyled,
  TextInputStyled,
  InputPrefixStyled,
  InputErrorStyled,
  TextAreaStyled,
  DropDownBoxStyled,
  DropDownErrorStyled,
};
