import multiRequest from '../shared/multiRequest';

const getTimeSlot = (addressId) => {
  return multiRequest([
    {
      url: `/fiber/customer/searchTimeSlot`,
      method: 'POST',
      data: {
        addressId,
      },
    },
  ]);
};

const terminateOrder = (data) => {
  return multiRequest([
    {
      url: `/fiber/customer/terminateOrder`,
      method: 'POST',
      data,
    },
  ]);
};

const DownService = {
  getTimeSlot,
  terminateOrder,
};

export default DownService;
